const neighborhoods = [


  // Manhattan
  { borough: "Manhattan", neighborhood: "Alphabet City", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/AlphabetCity.jpg" },
  { borough: "Manhattan", neighborhood: "Battery Park City", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/BatteryParkCity.webp" },
  { borough: "Manhattan", neighborhood: "Bloomingdale District", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/BloomingdaleDistrict.png" },
  { borough: "Manhattan", neighborhood: "Bowery", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/Bowery.jpg" },
  { borough: "Manhattan", neighborhood: "Carnegie Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/CarnegieHill.jpg" },
  { borough: "Manhattan", neighborhood: "Central Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/CentralPark.jpg" },
  { borough: "Manhattan", neighborhood: "Chelsea", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/Chelsea.jpg" },
  { borough: "Manhattan", neighborhood: "Chinatown", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/Chinatown.jpg" },
  { borough: "Manhattan", neighborhood: "Civic Center", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/CivicCenter.jpg" },
  { borough: "Manhattan", neighborhood: "East Harlem", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/EastHarlem.jpg" },
  { borough: "Manhattan", neighborhood: "East Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/EastVillage.webp" },
  { borough: "Manhattan", neighborhood: "Financial District", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/FinancialDistrict.jpg" },
  { borough: "Manhattan", neighborhood: "Flatiron District", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/FlatironDistrict.jpg" },
  { borough: "Manhattan", neighborhood: "Fort George", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/FortGeorge+.jpg" },
  { borough: "Manhattan", neighborhood: "Garment District", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/GarmentDistrict+.jpg" },
  { borough: "Manhattan", neighborhood: "Governors Island", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/GovernorsIsland+.jpg" },
  { borough: "Manhattan", neighborhood: "Gramercy", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/Gramercy.jpg" },
  { borough: "Manhattan", neighborhood: "Greenwich Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/GreenwichVillage.jpg" },
  { borough: "Manhattan", neighborhood: "Hamilton Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/HamiltonHeights.jpeg" },
  { borough: "Manhattan", neighborhood: "Harlem", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/Harlem.jpg" },
  { borough: "Manhattan", neighborhood: "Hell's Kitchen (Clinton)", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/HellsKitchen.jpg" },
  { borough: "Manhattan", neighborhood: "The Highline", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/TheHighline.jpeg" },
  { borough: "Manhattan", neighborhood: "Hudson Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/HudsonHeights.jpg" },
  { borough: "Manhattan", neighborhood: "Hudson Square", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/HudsonSquare.jpg" },
  { borough: "Manhattan", neighborhood: "Hudson Yards", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/HudsonYards.png" },
  { borough: "Manhattan", neighborhood: "Inwood", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/Inwood.jpg" },
  { borough: "Manhattan", neighborhood: "Kips Bay", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/KipsBay.jpg" },
  { borough: "Manhattan", neighborhood: "Lenox Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/LenoxHill.jpg" },
  { borough: "Manhattan", neighborhood: "Lincoln Square", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/LincolnSquare.jpg" },
  { borough: "Manhattan", neighborhood: "Little Italy/NoLIta", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/LittleItalyNoLIta.jpg" },
  { borough: "Manhattan", neighborhood: "Lower East Side", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/LowerEastSide.jpg" },
  { borough: "Manhattan", neighborhood: "Manhattan Valley", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/ManhattanValley.jpg" },
  { borough: "Manhattan", neighborhood: "Manhattanville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/Manhattanville.jpg" },
  { borough: "Manhattan", neighborhood: "Marble Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/MarbleHill.jpg" },
  { borough: "Manhattan", neighborhood: "Meatpacking District", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/MeatpackingDistrict.jpg" },
  { borough: "Manhattan", neighborhood: "Metropolitan Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/MetropolitanHill.jpg" },
  { borough: "Manhattan", neighborhood: "Midtown", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/Midtown.webp" },
  { borough: "Manhattan", neighborhood: "Midtown East", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/MidtownEast.jpg" },
  { borough: "Manhattan", neighborhood: "Midtown South", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/MidtownSouth.webp" },
  { borough: "Manhattan", neighborhood: "Morningside Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/MorningsideHeights.webp" },
  { borough: "Manhattan", neighborhood: "Murray Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/MurrayHill.webp" },
  { borough: "Manhattan", neighborhood: "NoHo", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/NoHo.jpg" },
  { borough: "Manhattan", neighborhood: "NoMad", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/NoMad.jpg" },
  { borough: "Manhattan", neighborhood: "Randall's Island", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/RandallsIsland.webp" },
  { borough: "Manhattan", neighborhood: "Roosevelt Island", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/RooseveltIsland.jpg" },
  { borough: "Manhattan", neighborhood: "Rose Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/RoseHill.jpg" },
  { borough: "Manhattan", neighborhood: "SoHo", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/SoHo.jpg" },
  { borough: "Manhattan", neighborhood: "South Street Seaport", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/SouthStreetSeaport.jpeg" },
  { borough: "Manhattan", neighborhood: "Spanish Harlem (El Barrio)", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/SpanishHarlem.jpg" },
  { borough: "Manhattan", neighborhood: "Stuyvesant Town", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/StuyvesantTown.jpg" },
  { borough: "Manhattan", neighborhood: "Sugar Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/SugarHill.webp" },
  { borough: "Manhattan", neighborhood: "Sutton Place", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/SuttonPlace.webp" },
  { borough: "Manhattan", neighborhood: "Theater District (Times Square)", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/TheaterDistrict.jpg" },
  { borough: "Manhattan", neighborhood: "TriBeCa", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/TriBeCa.jpg" },
  { borough: "Manhattan", neighborhood: "Tudor City", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/TudorCity.jpg" },
  { borough: "Manhattan", neighborhood: "Turtle Bay", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/TurtleBay.jpg" },
  { borough: "Manhattan", neighborhood: "Two Bridges", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/TwoBridges.jpg" },
  { borough: "Manhattan", neighborhood: "Union Square", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/UnionSquare.jpg" },
  { borough: "Manhattan", neighborhood: "Upper East Side", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/UpperEastSide.jpg" },
  { borough: "Manhattan", neighborhood: "Upper West Side", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/UpperWestSide.jpg" },
  { borough: "Manhattan", neighborhood: "Washington Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/WashingtonHeights.webp" },
  { borough: "Manhattan", neighborhood: "West Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/WestVillage.jpg" },
  { borough: "Manhattan", neighborhood: "World Trade Center", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/WorldTradeCenter.jpg" },
  { borough: "Manhattan", neighborhood: "Yorkville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Manhattan/Yorkville.jpg" },

  // Brooklyn
  { borough: "Brooklyn", neighborhood: "Albemarle", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Albemarle.jpg" },
  { borough: "Brooklyn", neighborhood: "Bath Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/BathBeach.jpg" },
  { borough: "Brooklyn", neighborhood: "Bay Ridge", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/BayRidge.jpg" },
  { borough: "Brooklyn", neighborhood: "Bedford-Stuyvesant", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/BedfordStuyvesant.jpg" },
  { borough: "Brooklyn", neighborhood: "Bensonhurst", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Bensonhurst.jpg" },
  { borough: "Brooklyn", neighborhood: "Bergen Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/BergenBeach.jpg" },
  { borough: "Brooklyn", neighborhood: "Boerum Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/BoerumHill.webp" },
  { borough: "Brooklyn", neighborhood: "Borough Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/BoroughPark.jpg" },
  { borough: "Brooklyn", neighborhood: "Brighton Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/BrightonBeach.jpg" },
  { borough: "Brooklyn", neighborhood: "Brooklyn Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/BrooklynHeights.jpg" },
  { borough: "Brooklyn", neighborhood: "Brooklyn Navy Yard", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/BrooklynNavyYard.jpeg" },
  { borough: "Brooklyn", neighborhood: "Brownsville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Brownsville.jpg" },
  { borough: "Brooklyn", neighborhood: "Bushwick", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Bushwick.jpg" },
  { borough: "Brooklyn", neighborhood: "Canarsie", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Canarsie.jpg" },
  { borough: "Brooklyn", neighborhood: "Carroll Gardens", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/CarrollGardens.jpg" },
  { borough: "Brooklyn", neighborhood: "City Line", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/CityLine.jpg" },
  { borough: "Brooklyn", neighborhood: "Clinton Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/ClintonHill.jpg" },
  { borough: "Brooklyn", neighborhood: "Cobble Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/CobbleHill.jpg" },
  { borough: "Brooklyn", neighborhood: "Columbia Street Waterfront", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/ColumbiaStreetWaterfront.jpg" },
  { borough: "Brooklyn", neighborhood: "Coney Island", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/ConeyIsland.jpg" },
  { borough: "Brooklyn", neighborhood: "Crown Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/CrownHeights.jpg" },
  { borough: "Brooklyn", neighborhood: "Cypress Hills", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/CypressHills.jpg" },
  { borough: "Brooklyn", neighborhood: "Dahill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Dahill.jpeg" },
  { borough: "Brooklyn", neighborhood: "Downtown Brooklyn", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/DowntownBrooklyn.jpg" },
  { borough: "Brooklyn", neighborhood: "DUMBO", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/DUMBO.webp" },
  { borough: "Brooklyn", neighborhood: "Dyker Beach Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/DykerBeachPark.jpg" },
  { borough: "Brooklyn", neighborhood: "Dyker Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/DykerHeights.jpg" },
  { borough: "Brooklyn", neighborhood: "East Flatbush", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/EastFlatbush.jpg" },
  { borough: "Brooklyn", neighborhood: "East New York", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/EastNewYork.jpg" },
  { borough: "Brooklyn", neighborhood: "East Williamsburg", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/EastWilliamsburg.webp" },
  { borough: "Brooklyn", neighborhood: "Erasmus", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Erasmus.jpg" },
  { borough: "Brooklyn", neighborhood: "Farragut", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Farragut.jpg" },
  { borough: "Brooklyn", neighborhood: "Flatbush", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Flatbush.webp" },
  { borough: "Brooklyn", neighborhood: "Flatlands", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Flatlands.jpg" },
  { borough: "Brooklyn", neighborhood: "Fort Greene", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/FortGreene.jpg" },
  { borough: "Brooklyn", neighborhood: "Fort Hamilton", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/FortHamilton.webp" },
  { borough: "Brooklyn", neighborhood: "Georgetown", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Georgetown.jpg" },
  { borough: "Brooklyn", neighborhood: "Gerritsen Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/GerritsenBeach.jpg" },
  { borough: "Brooklyn", neighborhood: "Gowanus", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Gowanus.jpg" },
  { borough: "Brooklyn", neighborhood: "Gravesend", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Gravesend.jpg" },
  { borough: "Brooklyn", neighborhood: "Greenpoint", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Greenpoint.jpg" },
  { borough: "Brooklyn", neighborhood: "Greenwood Cemetery", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/GreenwoodCemetery.jpg" },
  { borough: "Brooklyn", neighborhood: "Greenwood Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/GreenwoodHeights.jpg" },
  { borough: "Brooklyn", neighborhood: "Highland Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/HighlandPark.jpg" },
  { borough: "Brooklyn", neighborhood: "Homecrest", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Homecrest.jpg" },
  { borough: "Brooklyn", neighborhood: "Kensington", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Kensington.jpg" },
  { borough: "Brooklyn", neighborhood: "Los Sures - Southside", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/LosSuresSouthside.webp" },
  { borough: "Brooklyn", neighborhood: "Manhattan Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/ManhattanBeach.jpg" },
  { borough: "Brooklyn", neighborhood: "Madison", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Madison.jpg" },
  { borough: "Brooklyn", neighborhood: "Mapleton", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Mapleton.jpg" },
  { borough: "Brooklyn", neighborhood: "Marine Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/MarinePark.jpg" },
  { borough: "Brooklyn", neighborhood: "Midwood", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Midwood.jpg" },
  { borough: "Brooklyn", neighborhood: "Midwood Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/MidwoodPark.jpg" },
  { borough: "Brooklyn", neighborhood: "Mill Basin", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/MillBasin.jpg" },
  { borough: "Brooklyn", neighborhood: "New Lots", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/NewLots.jpg" },
  { borough: "Brooklyn", neighborhood: "Northside", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Northside.jpg" },
  { borough: "Brooklyn", neighborhood: "Ocean Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/OceanHill.jpg" },
  { borough: "Brooklyn", neighborhood: "Paerdegat", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Paerdegat.jpg" },
  { borough: "Brooklyn", neighborhood: "Park Slope", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/ParkSlope.jpg" },
  { borough: "Brooklyn", neighborhood: "Parkville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Parkville.jpg" },
  { borough: "Brooklyn", neighborhood: "Prospect Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/ProspectHeights.jpg" },
  { borough: "Brooklyn", neighborhood: "Prospect-Lefferts Gardens", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/ProspectLeffertsGardens.jpg" },
  { borough: "Brooklyn", neighborhood: "Prospect Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/ProspectPark.jpg" },
  { borough: "Brooklyn", neighborhood: "Prospect Park South", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/ProspectParkSouth.jpg" },
  { borough: "Brooklyn", neighborhood: "Red Hook", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/RedHook.jpg" },
  { borough: "Brooklyn", neighborhood: "Remsen Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/RemsenVillage.jpg" },
  { borough: "Brooklyn", neighborhood: "Seagate", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Seagate.jpg" },
  { borough: "Brooklyn", neighborhood: "Sheepshead Bay", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/SheepsheadBay.webp" },
  { borough: "Brooklyn", neighborhood: "South Slope", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/SouthSlope.jpg" },
  { borough: "Brooklyn", neighborhood: "South Williamsburg", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/SouthWilliamsburg.webp" },
  { borough: "Brooklyn", neighborhood: "Starrett City", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/StarrettCity.jpg" },
  { borough: "Brooklyn", neighborhood: "Stuyvesant Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/StuyvesantHeights.jpg" },
  { borough: "Brooklyn", neighborhood: "Sunset Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/SunsetPark.jpg" },
  { borough: "Brooklyn", neighborhood: "Victorian Flatbush", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/VictorianFlatbush.jpg" },
  { borough: "Brooklyn", neighborhood: "Vinegar Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/VinegarHill.jpg" },
  { borough: "Brooklyn", neighborhood: "Williamsburg", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Williamsburg.jpg" },
  { borough: "Brooklyn", neighborhood: "Windsor Terrace", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/WindsorTerrace.webp" },
  { borough: "Brooklyn", neighborhood: "Wingate", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Brooklyn/Wingate.jpg" },
  // The Bronx
  { borough: "The Bronx", neighborhood: "Allerton", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Allerton.jpg" },
  { borough: "The Bronx", neighborhood: "Baychester", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Baychester.jpg" },
  { borough: "The Bronx", neighborhood: "Bedford Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/BedfordPark.jpeg" },
  { borough: "The Bronx", neighborhood: "Belmont", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Belmont.jpg" },
  // { borough: "The Bronx", neighborhood: "Bronx Park" },
  // { borough: "The Bronx", neighborhood: "Bronx River" },
  { borough: "The Bronx", neighborhood: "Bronxwood", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Bronxwood.jpg" },
  { borough: "The Bronx", neighborhood: "Castle Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/CastleHill.jpg" },
  { borough: "The Bronx", neighborhood: "City Island", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/City%2BIsland.jpg" },
  { borough: "The Bronx", neighborhood: "Claremont", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Claremont.jpg" },
  { borough: "The Bronx", neighborhood: "Claremont Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Claremont%2BVillage.jpg" },
  { borough: "The Bronx", neighborhood: "Clason Point", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Clason%2BPoint.jpg" },
  { borough: "The Bronx", neighborhood: "Co-Op City", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/CoopCity.jpg" },
  { borough: "The Bronx", neighborhood: "Concourse", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Concouse.jpg" },
  { borough: "The Bronx", neighborhood: "Concourse Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/concourseVillage.jpg" },
  { borough: "The Bronx", neighborhood: "Country Club", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Country%2BClub.jpg" },
  { borough: "The Bronx", neighborhood: "Crotona", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Crotona.jpg" },
  { borough: "The Bronx", neighborhood: "Crotona Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Crotona%2BPark.jpg" },
  { borough: "The Bronx", neighborhood: "East Tremont", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/East%2BTremont.jpg" },
  { borough: "The Bronx", neighborhood: "Eastchester", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Eastchester.jpg" },
  { borough: "The Bronx", neighborhood: "Eastchester Bay", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Eastchester%2BBay.jpg" },
  { borough: "The Bronx", neighborhood: "Edenwald", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Edenwald.jpg" },
  { borough: "The Bronx", neighborhood: "Fieldston", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Fieldston.png" },
  { borough: "The Bronx", neighborhood: "Fordham Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Fordham%2BHeights.jpg" },
  { borough: "The Bronx", neighborhood: "Fordham Manor", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Fordham%2BManor.jpg" },
  { borough: "The Bronx", neighborhood: "Foxhurst", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Foxhurst.jpg" },
  { borough: "The Bronx", neighborhood: "Harding Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Harding%2BPark.jpg" },
  { borough: "The Bronx", neighborhood: "Highbridge", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Highbridge.jpg" },
  { borough: "The Bronx", neighborhood: "Hunts Point", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Hunts%2BPoint.jpg" },
  { borough: "The Bronx", neighborhood: "Indian Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Indian-Village.jpg" },
  { borough: "The Bronx", neighborhood: "Jerome Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/JeromePark.jpg" },
  { borough: "The Bronx", neighborhood: "Kingsbridge", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Kingsbridge.jpg" },
  { borough: "The Bronx", neighborhood: "Kingsbridge Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Kingsbridge%2BHeights.jpg" },
  { borough: "The Bronx", neighborhood: "Laconia", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Laconia.jpg" },
  { borough: "The Bronx", neighborhood: "Locust Point", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Locust%2BPoint.jpg" },
  { borough: "The Bronx", neighborhood: "Longwood", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/longwood.jpg" },
  { borough: "The Bronx", neighborhood: "Melrose", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Melrose.jpg" },
  { borough: "The Bronx", neighborhood: "Morris Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Morrisheights.jpeg" },
  { borough: "The Bronx", neighborhood: "Morris Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Morris%2BPark.jpg" },
  { borough: "The Bronx", neighborhood: "Morrisania", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Morrisania.jpg" },
  { borough: "The Bronx", neighborhood: "Mosholu", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Mosholu.jpg" },
  { borough: "The Bronx", neighborhood: "Mott Haven", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Mott+Haven.jpg" },
  { borough: "The Bronx", neighborhood: "Mt. Eden", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Mt.%2BEden.jpg" },
  { borough: "The Bronx", neighborhood: "Mt. Hope", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/mount-hope.jpg" },
  { borough: "The Bronx", neighborhood: "North Riverdale", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/NorthRiverdale.jpg" },
  { borough: "The Bronx", neighborhood: "Norwood", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/norwood.jpg" },
  { borough: "The Bronx", neighborhood: "Olinville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Olinville.jpg" },
  { borough: "The Bronx", neighborhood: "Park of Edgewater", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Park%2BOf%2BEdgewater.jpg" },
  { borough: "The Bronx", neighborhood: "Parkchester", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Parchester.jpg" },
  { borough: "The Bronx", neighborhood: "Pelham Bay", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/PelhamBay.jpg" },
  { borough: "The Bronx", neighborhood: "Pelham Bay Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/pelham-bay-park.jpg" },
  { borough: "The Bronx", neighborhood: "Pelham Gardens", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Pelham%2BGardens.jpg" },
  { borough: "The Bronx", neighborhood: "Pelham Parkway", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Pelham+Parkway.jpeg" },
  { borough: "The Bronx", neighborhood: "Port Morris", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/PortMorris.jpg" },
  { borough: "The Bronx", neighborhood: "Riverdale", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Riverdale.jpg" },
  { borough: "The Bronx", neighborhood: "Schuylerville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Schuylerville.jpg" },
  { borough: "The Bronx", neighborhood: "Silver Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/SilverBeach.jpg" },
  { borough: "The Bronx", neighborhood: "Soundview", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Soundview.jpeg" },
  { borough: "The Bronx", neighborhood: "Soundview Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/SoundViewPark.jpg" },
  { borough: "The Bronx", neighborhood: "Spuyten Duyvil", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/SpuytenDuyvil.jpg" },
  { borough: "The Bronx", neighborhood: "Throggs Neck", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Throggs%2BNeck.jpg" },
  { borough: "The Bronx", neighborhood: "Unionport", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Unionport.jpg" },
  { borough: "The Bronx", neighborhood: "University Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/University%2BHeights.jpg" },
  { borough: "The Bronx", neighborhood: "Van Cortlandt Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/VanCortlandtPark.jpg" },
  { borough: "The Bronx", neighborhood: "Van Cortlandt Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Van%2BCortlandt%2BVillage.jpg" },
  { borough: "The Bronx", neighborhood: "Van Nest", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Van%2BNest.jpg" },
  { borough: "The Bronx", neighborhood: "Wakefield", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Wakefield.jpg" },
  { borough: "The Bronx", neighborhood: "West Farms", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/West%2BFarms.jpg" },
  { borough: "The Bronx", neighborhood: "Westchester Square", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/westchester-square.jpg" },
  { borough: "The Bronx", neighborhood: "Williamsbridge", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Williamsbridge.jpg" },
  { borough: "The Bronx", neighborhood: "Woodlawn Cemetery", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/WoodlawnCemetery.jpg" },
  { borough: "The Bronx", neighborhood: "Woodlawn Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/TheBronx/Woodlawn%2BHeights.jpg" },


  // Queens
  // { borough: "Queens", neighborhood: "Alley Pond Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/AlleyPondPark.jpg" },
  { borough: "Queens", neighborhood: "Arverne", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Arverne.jpeg" },
  { borough: "Queens", neighborhood: "Astoria", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Astoria.jpg" },
  { borough: "Queens", neighborhood: "Astoria Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/AstoriaHeights.jpg" },
  { borough: "Queens", neighborhood: "Auburndale", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Auburndale.jpg" },
  { borough: "Queens", neighborhood: "Bay Terrace", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/BayTerrace.jpeg" },
  { borough: "Queens", neighborhood: "Bayside", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Bayside.jpg" },
  { borough: "Queens", neighborhood: "Bayside Hills", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/BaysideHills.jpg" },
  { borough: "Queens", neighborhood: "Bayswater", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Bayswater.jpg" },
  { borough: "Queens", neighborhood: "Beechhurst", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Beechhurst.jpg" },
  { borough: "Queens", neighborhood: "Bellaire", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Bellaire.jpg" },
  { borough: "Queens", neighborhood: "Belle Harbor", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/BelleHarbor.jpg" },
  { borough: "Queens", neighborhood: "Bellerose", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Bellerose.jpg" },
  { borough: "Queens", neighborhood: "Bellerose Manor", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/BelleroseManor.jpg" },
  { borough: "Queens", neighborhood: "Breezy Point", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/BreezyPoint.jpg" },
  { borough: "Queens", neighborhood: "Briarwood", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Briarwood.jpg" },
  { borough: "Queens", neighborhood: "Broad Channel", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/BroadChannel.jpeg" },
  { borough: "Queens", neighborhood: "Broadway-Flushing", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/BroadwayFlushing.jpg" },
  { borough: "Queens", neighborhood: "Brookville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Brookville.jpg" },

  { borough: "Queens", neighborhood: "Cambria Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/CambriaHeights.jpg" },
  { borough: "Queens", neighborhood: "College Point", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/CollegePoint.jpeg" },
  { borough: "Queens", neighborhood: "Corona", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Corona.jpg" },
  { borough: "Queens", neighborhood: "Cunningham Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/CunninghamPark.jpg" },
  { borough: "Queens", neighborhood: "Ditmars-Steinway", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/DitmarsSteinway.jpg" },
  { borough: "Queens", neighborhood: "Douglas Manor", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/DouglasManor.webp" },
  { borough: "Queens", neighborhood: "Douglaston", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Douglaston.jpg" },
  { borough: "Queens", neighborhood: "Downtown Flushing", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/DowntownFlushing.jpg" },
  { borough: "Queens", neighborhood: "East Elmhurst", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/EastElmhurst.webp" },
  { borough: "Queens", neighborhood: "Edgemere", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Edgemere.jpg" },
  { borough: "Queens", neighborhood: "Elmhurst", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Elmhurst.jpeg" },
  { borough: "Queens", neighborhood: "Far Rockaway", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/FarRockaway.jpg" },
  { borough: "Queens", neighborhood: "Floral Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/FloralPark.jpg" },
  { borough: "Queens", neighborhood: "Flushing Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/FlushingHeights.jpg" },
  { borough: "Queens", neighborhood: "Flushing Meadows Corona Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/FlushingMeadowsCoronaPark.jpg" },
  { borough: "Queens", neighborhood: "Forest Hills", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/ForestHills.jpg" },
  { borough: "Queens", neighborhood: "Forest Hills Gardens", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/ForestHillsGardens.jpg" },
  { borough: "Queens", neighborhood: "Forest Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/ForestPark.jpg" },
  { borough: "Queens", neighborhood: "Fort Tilden-Jacob Riis Parks", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/FortTildenJacobRiisParks.jpg" },
  { borough: "Queens", neighborhood: "Fresh Meadows", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/FreshMeadows.webp" },
  { borough: "Queens", neighborhood: "Glen Oaks", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/GlenOaks.jpeg" },
  { borough: "Queens", neighborhood: "Glendale", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Glendale.jpg" },
  { borough: "Queens", neighborhood: "Hamilton Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/HamiltonBeach.jpg" },
  { borough: "Queens", neighborhood: "Hillcrest", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Hillcrest.jpg" },
  { borough: "Queens", neighborhood: "The Hole", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/TheHole.jpg" },
  { borough: "Queens", neighborhood: "Hollis", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Hollis.webp" },
  { borough: "Queens", neighborhood: "Hollis Hills", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/HollisHills.webp" },
  { borough: "Queens", neighborhood: "Holliswood", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Holliswood.jpeg" },
  { borough: "Queens", neighborhood: "Howard Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/HowardBeach.jpg" },
  { borough: "Queens", neighborhood: "Hunter's Point", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/HuntersPoint.jpg" },
  { borough: "Queens", neighborhood: "Jackson Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/JacksonHeights.jpg" },
  { borough: "Queens", neighborhood: "Jamaica", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Jamaica.jpg" },
  { borough: "Queens", neighborhood: "Jamaica Center", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/JamaicaCenter.jpg" },
  { borough: "Queens", neighborhood: "Jamaica Estates", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/JamaicaEstates.jpg" },
  { borough: "Queens", neighborhood: "Jamaica Hills", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/JamaicaHills.jpg" },
  { borough: "Queens", neighborhood: "Kew Gardens", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/KewGardens.webp" },
  { borough: "Queens", neighborhood: "Kew Gardens Hills", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/KewGardensHills.jpg" },
  { borough: "Queens", neighborhood: "Kissena", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Kissena.jpg" },
  { borough: "Queens", neighborhood: "Kissena Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/KissenaPark.jpg" },
  { borough: "Queens", neighborhood: "Laurelton", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Laurelton.jpeg" },
  { borough: "Queens", neighborhood: "Linden Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/LindenHill.jpg" },
  { borough: "Queens", neighborhood: "Lindenwood", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Lindenwood.jpg" },
  { borough: "Queens", neighborhood: "Little Neck", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/LittleNeck.jpg" },
  { borough: "Queens", neighborhood: "Locust Manor", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/LocustManor.webp" },
  { borough: "Queens", neighborhood: "Long Island City", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/LongIslandCity.jpg" },
  { borough: "Queens", neighborhood: "Malba", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Malba.jpg" },
  { borough: "Queens", neighborhood: "Maspeth", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Maspeth.webp" },
  { borough: "Queens", neighborhood: "Meadowmere", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Meadowmere.jpg" },
  { borough: "Queens", neighborhood: "Middle Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/MiddleVillage.jpg" },
  { borough: "Queens", neighborhood: "Murray Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/MurrayHill.jpg" },
  { borough: "Queens", neighborhood: "Neponsit", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Neponsit.jpg" },
  { borough: "Queens", neighborhood: "New Hyde Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/NewHydePark.jpg" },
  { borough: "Queens", neighborhood: "North Corona", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/NorthCorona.jpg" },
  { borough: "Queens", neighborhood: "Oakland Gardens", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/OaklandGardens.jpg" },
  { borough: "Queens", neighborhood: "Old Howard Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/OldHowardBeach.jpg" },
  { borough: "Queens", neighborhood: "Ozone Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/OzonePark.jpg" },
  { borough: "Queens", neighborhood: "Pomonok", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Pomonok.jpg" },
  { borough: "Queens", neighborhood: "Queens Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/QueensVillage.jpeg" },
  { borough: "Queens", neighborhood: "Queensboro Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/QueensboroHill.jpg" },
  { borough: "Queens", neighborhood: "Rego Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/RegoPark.jpg" },
  { borough: "Queens", neighborhood: "Richmond Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/RichmondHill.jpg" },
  { borough: "Queens", neighborhood: "Richmond Hill East", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/RichmondHillEast.jpg" },
  { borough: "Queens", neighborhood: "Ridgewood", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Ridgewood.jpeg" },
  { borough: "Queens", neighborhood: "Rochdale", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Rochdale.jpg" },
  { borough: "Queens", neighborhood: "Rockaway Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/RockawayBeach.jpg" },
  { borough: "Queens", neighborhood: "Rockaway Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/RockawayPark.jpg" },
  { borough: "Queens", neighborhood: "Rosedale", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Rosedale.jpg" },
  { borough: "Queens", neighborhood: "Roxbury", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Roxbury.jpg" },
  { borough: "Queens", neighborhood: "South Jamaica", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/SouthJamaica.webp" },
  { borough: "Queens", neighborhood: "South Ozone Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/SouthOzonePark.jpg" },
  { borough: "Queens", neighborhood: "South Richmond Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/SouthRichmondHill.jpg" },
  { borough: "Queens", neighborhood: "Springfield Gardens", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/SpringfieldGardens.jpg" },
  { borough: "Queens", neighborhood: "St. Albans", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/StAlbans.jpeg" },
  { borough: "Queens", neighborhood: "Sunnyside", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Sunnyside.jpg" },
  { borough: "Queens", neighborhood: "Utopia", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Utopia.jpg" },
  { borough: "Queens", neighborhood: "Whitestone", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Whitestone.jpg" },
  { borough: "Queens", neighborhood: "Willets Point", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/WilletsPoint.jpg" },
  { borough: "Queens", neighborhood: "Woodhaven", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Woodhaven.jpg" },
  { borough: "Queens", neighborhood: "Woodside", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/Queens/Woodside.jpg" },

  // Staten Island
  { borough: "Staten Island", neighborhood: "Annadale", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Annadale.jpg" },
  { borough: "Staten Island", neighborhood: "Arden Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/ArdenHeights.jpg" },
  { borough: "Staten Island", neighborhood: "Arlington", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Arlington.jpg" },
  { borough: "Staten Island", neighborhood: "Arrochar", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Arrochar.jpeg" },
  { borough: "Staten Island", neighborhood: "Bay Terrace", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/BayTerrace.jpg" },
  { borough: "Staten Island", neighborhood: "Bloomfield", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Bloomfield.webp" },
  { borough: "Staten Island", neighborhood: "Brighton Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/BrightonHeights.jpg" },
  { borough: "Staten Island", neighborhood: "Bull's Head", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/BullsHead.jpg" },
  { borough: "Staten Island", neighborhood: "Castleton Corners", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/CastletonCorners.jpg" },
  { borough: "Staten Island", neighborhood: "Charleston", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Charleston.jpg" },
  { borough: "Staten Island", neighborhood: "Clifton", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Clifton.jpg" },
  { borough: "Staten Island", neighborhood: "Clove Lakes Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/CloveLakesPark.jpg" },
  { borough: "Staten Island", neighborhood: "Concord", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Concord.webp" },
  { borough: "Staten Island", neighborhood: "Dongan Hills", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/DonganHills.jpg" },
  { borough: "Staten Island", neighborhood: "Dongan Hills Colony", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/DonganHillsColony.jpg" },
  { borough: "Staten Island", neighborhood: "Eltingville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Eltingville.webp" },
  { borough: "Staten Island", neighborhood: "Emerson Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/EmersonHill.jpg" },
  { borough: "Staten Island", neighborhood: "Fort Wadsworth", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/FortWadsworth.jpeg" },
  { borough: "Staten Island", neighborhood: "Freshkills Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/FreshkillsPark.jpg" },
  { borough: "Staten Island", neighborhood: "Graniteville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Graniteville.jpg" },
  { borough: "Staten Island", neighborhood: "Grant City", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/GrantCity.jpg" },
  { borough: "Staten Island", neighborhood: "Grasmere", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Grasmere.jpg" },
  { borough: "Staten Island", neighborhood: "Great Kills", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/GreatKills.webp" },
  { borough: "Staten Island", neighborhood: "Great Kills Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/GreatKillsPark.jpg" },
  { borough: "Staten Island", neighborhood: "Grymes Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/GrymesHill.jpg" },
  { borough: "Staten Island", neighborhood: "Heartland Village", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/HeartlandVillage.jpg" },
  { borough: "Staten Island", neighborhood: "Huguenot North", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/HuguenotNorth.jpg" },
  { borough: "Staten Island", neighborhood: "Huguenot South", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/HuguenotSouth.jpg" },
  // { borough: "Staten Island", neighborhood: "Latourette Park", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/LatourettePark.jpg" },
  { borough: "Staten Island", neighborhood: "Lighthouse Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/LighthouseHill.jpg" },
  { borough: "Staten Island", neighborhood: "Manor Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/ManorHeights.jpg" },
  { borough: "Staten Island", neighborhood: "Mariners Harbor", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/MarinersHarbor.jpg" },
  { borough: "Staten Island", neighborhood: "Meier's Corners", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/MeiersCorners.jpg" },
  { borough: "Staten Island", neighborhood: "Midland Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/MidlandBeach.jpg" },
  { borough: "Staten Island", neighborhood: "New Brighton", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/NewBrighton.jpg" },
  { borough: "Staten Island", neighborhood: "New Dorp", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/NewDorp.jpg" },
  { borough: "Staten Island", neighborhood: "New Dorp Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/NewDorpBeach.jpg" },
  { borough: "Staten Island", neighborhood: "New Springville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/NewSpringville.jpg" },
  { borough: "Staten Island", neighborhood: "Oakwood", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Oakwood.jpg" },
  { borough: "Staten Island", neighborhood: "Old Town", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/OldTown.jpg" },
  { borough: "Staten Island", neighborhood: "Park Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/ParkHill.jpg" },
  { borough: "Staten Island", neighborhood: "Pleasant Plains", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/PleasantPlains.jpg" },
  { borough: "Staten Island", neighborhood: "Port Richmond", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/PortRichmond.jpeg" },
  { borough: "Staten Island", neighborhood: "Prince's Bay", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/PrincesBay.jpg" },
  { borough: "Staten Island", neighborhood: "Randall Manor", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/RandallManor.jpg" },
  { borough: "Staten Island", neighborhood: "Richmond Town", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/RichmondTown.webp" },
  { borough: "Staten Island", neighborhood: "Richmond Valley", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/RichmondValley.jpg" },
  { borough: "Staten Island", neighborhood: "Rosebank", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Rosebank.jpg" },
  { borough: "Staten Island", neighborhood: "Rossville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Rossville.jpg" },
  { borough: "Staten Island", neighborhood: "Sandy Ground", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/SandyGround.jpg" },
  { borough: "Staten Island", neighborhood: "Shore Acres", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/ShoreAcres.jpg" },
  { borough: "Staten Island", neighborhood: "Silver Lake", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/SilverLake.jpg" },
  { borough: "Staten Island", neighborhood: "South Beach", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/SouthBeach.jpg" },
  { borough: "Staten Island", neighborhood: "St. George", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/StGeorge.jpg" },
  { borough: "Staten Island", neighborhood: "Stapleton", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Stapleton.webp" },
  { borough: "Staten Island", neighborhood: "Stapleton Heights", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/StapletonHeights.jpg" },
  { borough: "Staten Island", neighborhood: "Sunset Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/SunsetHill.jpg" },
  { borough: "Staten Island", neighborhood: "Sunnyside", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Sunnyside.jpg" },
  { borough: "Staten Island", neighborhood: "Todt Hill", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/TodtHill.webp" },
  { borough: "Staten Island", neighborhood: "Tompkinsville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Tompkinsville.jpeg" },
  // { borough: "Staten Island", neighborhood: "Tottenville", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Tottenville.jpg" },
  { borough: "Staten Island", neighborhood: "Travis-Chelsea", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/TravisChelsea.jpg" },
  { borough: "Staten Island", neighborhood: "West New Brighton", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/WestNewBrighton.webp" },
  { borough: "Staten Island", neighborhood: "Westerleigh", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Westerleigh.jpg" },
  { borough: "Staten Island", neighborhood: "Willowbrook", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Willowbrook.jpeg" },
  { borough: "Staten Island", neighborhood: "Woodrow", imageUrl: "https://d1ys1weio2yffs.cloudfront.net/neighborhoods/StatenIsland/Woodrow.webp" }
];

export default neighborhoods;


